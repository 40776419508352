

// D3Landing.js
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import './Risograph.css';


const Risograph = () => {

    return (
          <div class="diamond"></div>
    );
};

export default Risograph;
